.div{
    margin-top: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.Button{
    margin-top: 1;
    margin-bottom: 2;
}
#password{

    -webkit-text-security: square;
   color:rgb(50, 51, 53);

}