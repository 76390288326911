.alignCenter{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: left;
    align-content: flex-start;

}
.alignCenterMargin{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    
}
.sup {
    vertical-align: super;
    font-size: smaller;
  }
.overviewBox{
    width:400px;
    border:2;
    border-radius: 5px;
    box-shadow: 0;

}


.img-zoom-container {
position: relative;
}

.img-zoom-lens {
position: absolute;
border: 1px solid #d4d4d4;
/*set the size of the lens:*/
width: 40px;
height: 40px;

}

.img-zoom-result {
border: 1px solid #d4d4d4;
/*set the size of the result div:*/
width: 300px;
height: 300px;
}
.h6Align{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: right;
    align-items:center;
}
.aref{
    font-size: x-small;
    
}
.add-buttonContainer{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 2px;
}
.PriceLables{
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
} 