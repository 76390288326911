
.container{
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  padding-left: 130px;
}
.item{
  margin:5px
}
.itemContainer{
  display: flex;
  flex-direction: column;

  padding: 5x;
}
.labelColor{
  font-size: small;
  font-variant-caps: all-petite-caps;
}
.selectedItem{
  margin:5px;
  border:1px solid black;
  border-width: 2px;
}