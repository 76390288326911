.mainWindow{

    width: 100%;
    height: 100%;

    flex-direction: row;
    display: flex;
    flex-basis: auto;
    justify-content: center;

    background-color: rgb(218, 219, 217);
}