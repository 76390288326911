table{
    border-collapse: separate;
    border-spacing: 0 .7em;
}

td{
    text-align: left;
    padding: 2px;

}
.tdcustom{
    text-align: left;
    padding: 2px;
    width: 80px;
}
.tdcustomH{
    text-align: left;
    padding: 2px;
    width: 80px;
    column-span: 3;
    font-size: medium;
}