
.form-wrapper {
   
    margin: 0 auto;
    box-shadow: 2px;
    border: 2 solid "black";
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.StackClass{
  direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 10px;
 
  
}
.buttonContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}
.space {
  width: 8px;
  height: auto;
  display: inline-block;
}
.outlined-basic{
  padding: 2px;
}