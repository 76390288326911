.img-wrapper {
    overflow: hidden;
  }
  
  img.hover-zoom {
    transition: all 0.3s ease 0s;
    width:40%;
    align-content: center;
  }
  img.hover-zoom:hover {
    transform: scale(4);
  }