.calc-header {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: top;
    padding-top: 10px;
    font-size: calc(10px + 2vmin);

  }