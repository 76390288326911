.headerClass {

    background-color: rgb(255, 255, 255);
    margin: 5px 0px 5px 0px;
    box-shadow: 0px 0px 0px 0px;
    width: 210mm;
    height:297mm ;
    margin-left: auto;
    margin-right: auto;
}

.InvtopHeader {
    margin-top: 5px;

    margin-left: 3px;
    display: flex;
    align-items: initial;
    justify-content: flex-start;
    flex-direction: column;
}

.InvtopHeadercontent {
    display: flex;
    align-items: initial;
    justify-content: space-between;
    flex-direction: row;
}

.logoinv {

    width: 20%;
    height: auto;
}

.boxline {
    display: flex;
    justify-content: flex-end;
    padding-top: 7px;
    padding-bottom: 7px;
    align-items: flex-end;
    background-color: black;
    padding-right: 5px;

}

.boxText {
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
    font-size: medium;
}

.headercolour {

    background-color: black;
    color: white;
    font-weight: 6;
    font-style: normal;
    padding: "0px 16px";
    height: 30px;
    min-height: 30px;

}

.detailBoxMain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;

}

.boxDetails {
    width: 260px;
    height: 250px;
    display: inline-block;
    align-items: flex-start;
    line-height: 12px;
}

.alignText {

    text-align: left;
}

.labeltexts {
    margin-top: 20px;
    line-break: auto;

}


.Address {
    margin-top: 20px;
}

.adressHeight {
    text-align: left;
    line-height: 14px;
}

.lowerText {
    padding-left: 10px;
    justify-content: left;
    display: flex;
    align-items: initial;
    align-items: flex-start;
    font-weight: 700;
    padding-bottom: 10px;
}

.TableCell {
    border-collapse: collapse;
    border-spacing: 0;
    padding: "10px 0px 10px 10px"
}

@media print {
    @page {
        margin-top: 0;
        margin-bottom: 0;
    }

    html,
    body {

        height: auto;
    }
    #footer{
        display:inline-block;
     }

    .headercolour {
        background-color: #000000 !important;
        color: white !important;
        print-color-adjust: exact;
    }

    .boxline {
        background-color: #000000 !important;
        color: white !important;
        print-color-adjust: exact;
    }

    #printbtn {
        display: none;
    }

    #printbtn1 {
        display: none;
    }

    #printPanel {
        display: none;
    }

    #btn_invoice {
        display: none;
    }

}
.footer {  
    display:none;
    position: fixed;  
    bottom: 2px;  
    width: 100%;  
    color: white;  
    text-align: center;  
    }  
.footerAlign{
    border-top: 1;
    border-style: solid;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-content: space-between;
    vertical-align: bottom;
}