#header {
    position: absolute;
    width: 220px;
  }
  #header .pro-sidebar {
    height: 100vh;
  }
  #header .closeMenu {
    color: #000;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 55px;
    cursor: pointer;
  }
  #header .pro-sidebar {
    width: 100%;
    min-width: 100%;

  }
  #header .pro-sidebar.collapsed {
    width: 0px;
    min-width: 0px;
  }
  #header .pro-sidebar-inner {
    background-color: white;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
    
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;

  }
  #header .pro-sidebar-inner .pro-sidebar-layout .logoText p {
    font-size: 20px;
    padding: 2 20px;
    color: #000;
    font-weight: bold;

  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 0px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #000;
    margin: 0px 0px ;
    font-weight: bold;

  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: transparent;
    color: #000;
    border-radius: 3px;
    display: flex;
    align-items: center;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
    align-items: center;
  }
  .pro-menu-item :hover {
    background-color: #fce685;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background-color: #fce685;
  }
  
  #header .logo {
    padding: 0px;
  }
  .sidebarfooter{
  position:absolute;
  left:0;
  bottom:0;
  right:0;
  height:130px
  }
  @media only screen and (max-width: 720px) {
    html {
      overflow: hidden;
    }
  }
 