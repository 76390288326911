#passwords {
  -webkit-text-security: square;
  color: rgb(50, 51, 53);
  margin-top: 16px ;

}

#button_cont {
padding-top: 10px;
}

  .outer_cont{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
  }
.div_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:300px;
    align-items: center
    
}
.div_container2{
    padding-bottom: 5px;
}
