.Updateitems{

padding-left: 20px;
padding-top: 10px;
margin: 20px;
align-items: center;
display: flex;
flex-direction: column;
}
.message{
margin:5px;
padding-top: 15px;
font-size: medium;
}
.Spacing{

width:400px;
justify-items:center;
align-items: center;
flex-wrap: nowrap;
display: flex;
flex-direction: row;
justify-content: space-between;
}